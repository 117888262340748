











































import { ILearnPackContent } from '@/models';
import { LEARNPACK_CONTENT_TYPE, propSync } from '@/utils';
import { defineComponent, PropType, ref, watch } from '@vue/composition-api';
import { TableList } from './components';

export default defineComponent({
  name: 'LearnPackTable',
  props: {
    list: {
      type: Array as PropType<ILearnPackContent[]>,
      required: true,
    },
  },
  components: {
    TableList,
  },
  setup(props, { emit }) {
    const learnPackTableRef = ref<HTMLDivElement>();
    const syncedList = propSync(props, 'list');

    function handleClickSearch(type: LEARNPACK_CONTENT_TYPE) {
      emit('search', type);
    }

    watch(
      syncedList.value,
      () => {
        setTimeout(() => {
          if (!learnPackTableRef.value) return;
          learnPackTableRef.value.scrollTop = learnPackTableRef.value.scrollHeight;
        }, 100);
      },
      {
        deep: true,
      },
    );
    return {
      LEARNPACK_CONTENT_TYPE,

      learnPackTableRef,

      syncedList,

      handleClickSearch,
    };
  },
});
