



















import { ILearnPackContent } from '@/models';
import { LEARNPACK_CONTENT_TYPE, propSync } from '@/utils';
import { defineComponent, PropType } from '@vue/composition-api';
import draggable from 'vuedraggable';

export default defineComponent({
  name: 'TableList',
  props: {
    list: {
      type: Array as PropType<ILearnPackContent[]>,
      required: true,
    },
  },
  components: {
    draggable,
  },
  setup(props) {
    const syncedList = propSync(props, 'list');

    function getContentType(type: LEARNPACK_CONTENT_TYPE) {
      if (type === LEARNPACK_CONTENT_TYPE.Lecture) {
        return '강의';
      }
      if (type === LEARNPACK_CONTENT_TYPE.Book) {
        return '도서';
      }

      return '외부링크';
    }

    function handleClickDelete(index: number) {
      syncedList.value.splice(index, 1);
    }

    return {
      syncedList,

      getContentType,

      handleClickDelete,
    };
  },
});
