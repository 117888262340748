































import { ILearnPack } from '@/models';
import { defineComponent, PropType } from '@vue/composition-api';

import { CONTENT_TYPE, propSync } from '@/utils';
import { bookMarkSearvice } from '@/services/bookMark';
import { LearnPackCard, LearnPackCardSkeleton } from '@/components';
import { RountName } from '@/router';

export default defineComponent({
  name: 'LearnPackList',
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Array as PropType<ILearnPack[]>,
      required: true,
    },
  },
  components: {
    LearnPackCard,
    LearnPackCardSkeleton,
  },
  setup(props, { root }) {
    const syncedData = propSync(props, 'data');

    async function handleToggleBookMark({
      marked,
      learnPackId,
      listIndex,
      bookMarkId,
    }: {
      marked: boolean;
      learnPackId: number;
      listIndex: number;
      bookMarkId: number | null;
    }) {
      try {
        if (marked) {
          const newBookMarkId = await bookMarkSearvice.createBookMark({
            contentId: learnPackId,
            contentType: CONTENT_TYPE.LearnPack,
          });

          syncedData.value[listIndex].bookMarkId = newBookMarkId;
        } else {
          if (!bookMarkId) return;
          await bookMarkSearvice.deleteBookMark(bookMarkId);
        }

        syncedData.value[listIndex].bookMarked = marked;
      } catch {
        // pass
      }
    }

    function handleClickLearnPack(id: number) {
      root.$router.push({
        name: RountName.LearnPackDetail,
        params: {
          learnPackId: String(id),
        },
      });
    }

    return {
      syncedData,

      handleToggleBookMark,
      handleClickLearnPack,
    };
  },
});
